<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="font-size: 16px;font-weight: 500;color: rgba(32, 32, 32, 1);margin-left: 30px;margin-top: 12px;">
            充值方式
        </div>

        <div
            style="width: 316px;height: 49px;opacity: 1;border-radius: 10px;background: rgba(32, 32, 32, 0.05);margin-left: 30px;margin-top: 16px;display: flex;">
            <img style="width: 25px;height: 25px;margin-left: 12px;margin-top: 15px;" src="../img/37.png"
                alt="">
            <div
                style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-top: 17px;margin-left: 17px;">
                微信充值
            </div>
            <img style="width: 7px;height: 12px;margin-left: 178px;margin-top: 20px;" src="../img/38.png" alt="">

        </div>
        <div
            style="font-size: 16px;font-weight: 500;color: rgba(32, 32, 32, 1);margin-top: 47px;margin-left: 30px;margin-bottom: 26px;">
            充值金额
        </div>

        <div style="margin: 0 26px;display: flex;flex-wrap: wrap;justify-content: space-between;">

            <div :class="{ bule: index == rule_id_index, black: index != rule_id_index }" v-for="(item, index) in list" :key="index"
                @click="rule_id_click(item, index)">
                <div style="font-size: 16px;text-align: center;margin-top: 10px;margin-bottom: 4px;">
                    ￥{{ item.recharge_amount }}
                </div>
                <div style="font-size: 12px;text-align: center;">
                    送{{ item.gift_amount }}元
                </div>

            </div>

            <!-- <div class="black">
                <div style="font-size: 0.16rem;text-align: center;margin-top: 0.1rem;margin-bottom: 0.04rem;">
                    ￥500.00
                </div>
                <div style="font-size: 0.12rem;text-align: center;">
                    送200元
                </div>

            </div> -->



        </div>

        <van-button @click="pay_recharge_on()" type="primary"
            style="margin-left: 30px;margin-top: 50px;font-size: 16px;width: 312px;height: 48px;opacity: 1;border-radius: 10px;box-shadow: 0px 2px 9px  rgba(50, 100, 237, 0.34);background: rgba(50, 100, 237, 1);">
            立即充值
        </van-button>





    </div>
</template>

<style scoped>
.black {
    width: 100px;
    height: 61px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(32, 32, 32, 0.05);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    color: rgba(32, 32, 32, 0.6);
    overflow: hidden;
    margin-top: 15px;
}

.bule {
    width: 100px;
    height: 61px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(50, 100, 237, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4), 0px 7px 13px -3px rgba(0, 0, 0, 0.3), inset 0px -0.03px 0px rgba(0, 0, 0, 0.2);
    color: rgba(255, 255, 255, 1);
    overflow: hidden;
    margin-top: 15px;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
import { get_recharge, pay_recharge } from "@/api/api";
import { Notify } from 'vant';
export default {
    name: 'Recharge',
    data() {
        return {
            value: '',
            device_id: '',
            list: [],
            rule_id_index: -1,

            openid: '',
            user_id: '',
            recharge_amount: '',//充值金额
            gift_amount: '',//赠送金额
            pay_recharge_obj:{},
        }

    },
    mounted() {
        // this.add()
        var azz = localStorage.getItem('device_id');
        if (azz!='') {
            azz = azz.split('=')[1]
        }
        
        this.device_id = azz
        console.log(this.device_id);

        var aqq = localStorage.getItem('openid');
        this.openid = aqq;

        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        this.user_id = use


        this.get_recharge_on()
    },
    methods: {
        // 充值套餐列表
        get_recharge_on() {
            get_recharge(this.device_id).then(res => {
                if (res.data.code == 200) {
                    this.list = res.data.data
                }
                console.log(res);
            })


        },

        // 点击变色
        rule_id_click(item, index) {
            this.rule_id_index = index
            console.log(item);
            this.recharge_amount = item.recharge_amount
            this.gift_amount = item.gift_amount
        },

        // 05-充值支付
        pay_recharge_on() {
            if (this.recharge_amount == '' || this.gift_amount == '') {
                Notify({ type: 'danger', message: '请选择充值套餐' });
                return
            }

            var data = {
                device_id: this.device_id,
                openid: this.openid,
                pay_type: 1,
                recharge_amount: this.recharge_amount,
                gift_amount: this.gift_amount,
                user_id: this.user_id
            }


            pay_recharge(data).then(res => {
                if (res.data.code==200) {
                    this.pay_recharge_obj=res.data.data
                    this.callpay()
                }
                console.log(res);
            })
        },


        jsApiCall() {
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', this.pay_recharge_obj,
                (res) => {

                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        console.log(res.err_msg);
                        this.$router.push('/recharge')
                    } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                        alert("用户取消支付!");
                    } else {
                        // alert(res.err_msg)
                        alert("支付失败!");
                    }
                }
            );
        },

        callpay() {
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.jsApiCall(), false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady',  this.jsApiCall());
                    document.attachEvent('onWeixinJSBridgeReady',  this.jsApiCall());
                }
            } else {
                this.jsApiCall();
            }
        }




    }

}


</script>

