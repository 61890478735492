import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/login.vue";
import Login_copy from "../views/login_copy.vue";
import RegIster from "../views/register.vue";
import Home from "../views/home.vue";
import My from "../views/my.vue";
import Purse from "../views/purse.vue";
import Recharge from "../views/recharge.vue";
import Orders from "../views/orders.vue";
import Certificates from "../views/certificates.vue";
import OrderDetails from "../views/order_details.vue";
import Lockers from "../views/lockers.vue";
import PickUp from "../views/pick_up.vue";
import PickUpdDetail from "../views/pick_up_detail.vue";
import PickUpPay from "../views/pick_up_pay.vue";
import Privacy from "../views/privacy.vue";
import Application from "../views/application.vue";
import Progress from "../views/progress.vue";
import Receipt from "../views/receipt.vue";
import Equipment from "../views/equipment.vue";
import Lattice from "../views/lattice.vue";
import AdministratorList from "../views/administrator_list.vue";
import StorageRecording from "../views/storage_recording.vue";


const routes = [
  // 登录页
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/login_copy",
    name: "login_copy",
    component: Login_copy,
  },
  // 注册页
  {
    path: "/register",
    name: "register",
    component: RegIster,
  },
  // 首页
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      BottomNavigation: true,
    },
  },
  // 个人信息页
  {
    path: "/my",
    name: "my",
    component: My,
    meta: {
      BottomNavigation: true,
    },
  },
  // 充值列表兼跳转
  {
    path: "/purse",
    name: "purse",
    component: Purse,
  },
  // 充值页
  {
    path: "/recharge",
    name: "recharge",
    component: Recharge,
  },
  // 订单页
  {
    path: "/orders",
    name: "orders",
    component: Orders,
  },
  // 存单详情
  {
    path: "/certificates",
    name: "certificates",
    component: Certificates,
  },
  // 订单详情
  {
    path: "/order_details",
    name: "order_details",
    component: OrderDetails,
  },
  // 存柜页面
  {
    path: "/lockers",
    name: "lockers",
    component: Lockers,
  },
  // 取货页面
  {
    path: "/pick_up",
    name: "pick_up",
    component: PickUp,
  },
  // 取货支付页面
  {
    path: "/pick_up_detail",
    name: "pick_up_detail",
    component: PickUpdDetail,
  },
  // 取货支付成功页面
  {
    path: "/pick_up_pay",
    name: "pick_up_pay",
    component: PickUpPay,
  },
  // 隐私及协议页面
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },
  // 申请退款
  {
    path: "/application",
    name: "application",
    component: Application,
  },
  // 查看退款流程
  {
    path: "/progress",
    name: "progress",
    component: Progress,
  },
  // 小票信息
  {
    path: "/receipt",
    name: "receipt",
    component: Receipt,
  },
  // 设备列表
  {
    path: "/equipment",
    name: "equipment",
    component: Equipment,
  },
  // 格子柜列表
  {
    path: "/lattice",
    name: "lattice",
    component: Lattice,
  },
  // 管理员列表
  {
    path: "/administrator_list",
    name: "administrator_list",
    component: AdministratorList,
  },
  // 快递员存储记录列表
  {
    path: "/storage_recording",
    name: "storage_recording",
    component: StorageRecording,
  },


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
