<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="width: 324px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 12px  rgba(0, 0, 0, 0.04);margin-left: 28px;overflow: hidden;">
            <div style="display: flex;align-items: center;justify-content: center;margin-top: 16px;">
                <img style="width: 78px;height: 78px;" :src="user_info_obj.avatar" alt="">
            </div>
            <div
                style="font-size: 16px;font-weight: 700;letter-spacing: 0;line-height: 50px;color: rgba(32, 32, 32, 1);text-align: center;">
                {{ user_info_obj.nickname }}
            </div>
            <div style="display: flex;align-items: center;justify-content: center;">
                <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);">
                    {{ user_info_obj.mobile }}
                </div>
                <img v-if="user_info_obj.role_type == 30" src="../img/19.png " alt="">
                <img v-if="user_info_obj.role_type == 40" src="../img/23.png " alt="">
                <img v-if="user_info_obj.role_type == 10" src="../img/24.png " alt="">
                <img v-if="user_info_obj.role_type == 20" src="../img/25.png " alt="">

            </div>

        </div>

        <div @click="purse"
            style="padding: 20px 15px 0 15px; width: 298px;height: 92px;opacity: 1;border-radius: 10px;background-image: url(http://dms.selfbao.com/h5/sxkmg/21.png) ;background-size: 100% 100%;overflow: hidden;display: flex;align-items: center;justify-content: space-between;margin-left: 23px;">
            <div>
                <div style="display: flex;align-items: center;">
                    <div style="font-size: 14px;font-weight: 500;color: rgba(255, 255, 255, 1);">
                        当前余额
                    </div>
                    <img style="width: 13px;height: 12px;margin-left: 5px;" src="../img/22.png" alt="">

                </div>
                <div
                    style="font-size: 32px;font-weight: 700;letter-spacing: 0;line-height: 38px;color: rgba(255, 255, 255, 1);">
                    {{ balance }}
                </div>
            </div>

            <van-button type="default"
                style="width: 72px;height: 29px;opacity: 1;border-radius: 78px;color: black;font-size: 14px;font-weight: 500;">充值</van-button>


        </div>

        <div
            style="margin-left: 28px;margin-top: 10px;overflow: hidden; width: 325px;height: 112px;opacity: 1;border-radius:10px;background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);box-shadow: 0 3px 5px  rgba(56, 56, 56, 0.25);">
            <div
                style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-top: 10px;margin-left: 11px;margin-bottom: 16px;">
                我的订单
            </div>
            <div @click="orders" style="display: flex;align-items: center;justify-content: space-between;">
                <div style="margin-left: 34px;">
                    <img style="width: 34px;height: 34px;" src="../img/26.png" alt="">
                    <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);text-align: center;">
                        全部
                    </div>
                </div>
                <div>
                    <img style="width: 34px;height: 34px;" src="../img/27.png" alt="">
                    <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);text-align: center;">
                        待取件
                    </div>
                </div>
                <div style="margin-right: 34px;">
                    <img style="width: 34px;height: 34px;" src="../img/28.png" alt="">
                    <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 0.6);text-align: center;">
                        已取件
                    </div>
                </div>

            </div>

        </div>

        <div
            style="margin-left: 28px;margin-top: 10px;width: 325px;height: 160px;opacity: 1;border-radius: 15px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);overflow: hidden;">
            <div style="display: flex;align-items: center;margin-top: 27px;margin-left: 22px;" v-if="expresser_permission==1" @click="storage_recording()">
                <img style="width: 20px;height: 20px;" src="../img/29.png" alt="">
                <div style="font-size: 13px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 18px;">
                    快递员存储记录
                </div>
            </div>
            
            <div style="display: flex;align-items: center;margin-top: 26px;margin-left: 22px;" @click="callPhone()">
                <img style="width: 20px;height: 20px;" src="../img/30.png" alt="">
                <div style="font-size: 13px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 18px;">
                    当地客服
                </div>
            </div>

            <!-- <div style="display: flex;align-items: center;margin-top: 0.26rem;margin-left: 0.22rem;">
                <img style="width: 0.2rem;height: 0.2rem;" src="../img/31.png" alt="">
                <div style="font-size: 0.13rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.18rem;">
                    投诉与建议
                </div>
            </div> -->



        </div>



    </div>
</template>

<style scoped>
.black {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(32, 32, 32, 1);
    text-align: left;
}

.bule {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(50, 100, 237, 1);
    text-align: left;
    vertical-align: top;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
import { user_info, user_balance } from "@/api/api";
export default {
    name: 'My',
    data() {
        return {
            value: '',
            user_id: '',
            device_id: '',
            user_info_obj: {},//用户信息
            balance: '',
            phoneNumber:18822556801,
            expresser_permission:'',
        }

    },
    mounted() {
        // this.add()
        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        // this.role_type = use.role_type
        this.user_id = use

        var azz = localStorage.getItem('device_id');
        azz = azz.split('=')[1]
        this.device_id = azz
        console.log(this.device_id);

        this.expresser_permission = localStorage.getItem('expresser_permission');

        this.user_info()
        this.user_balance_on()
    },
    methods: {
        // 01-1用户详情
        user_info() {
            user_info(this.user_id).then(res => {
                if (res.data.code == 200) {
                    console.log(res);
                    this.user_info_obj = res.data.data
                }
            })
        },

        // 06-账户信息-客户余额
        user_balance_on() {

            user_balance(this.device_id, this.user_id).then(res => {
                if (res.data.code == 200) {
                    console.log(res);
                    this.balance = res.data.data.balance
                }

            })
        },

        callPhone() {
            window.location.href = 'tel://' + this.phoneNumber
        },


        purse() {
            this.$router.push('/purse')
        },
        orders() {
            this.$router.push('/orders')
        },
        storage_recording() {
            this.$router.push('/storage_recording')
        },
    }

}


</script>

