<template>
	<div
		style="background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);height:100vh;overflow: hidden;">
		<div
			style="margin-left: 15px;margin-top: 86px;width: 345px;height: 515px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 1px 2px  rgba(0, 0, 0, 0.05);">
			<div style="text-align: center;">
				<img style="width: 82px;height: 82px;" :src='img_url' alt="">
			</div>
			<!-- <div style="font-size: 0.12rem;font-weight: 400;text-align: center;margin-top: 0.14rem;">
				上传头像
			</div> -->

			<van-cell-group inset
				style="width: 312px;height: 48px;opacity: 1;border-radius: 10px;margin-left: 17px;margin-top: 8px;">
				<van-field style="background: rgba(32, 32, 32, 0.05);height: 50px;font-size: 15px;align-items: center;" v-model="nickname" label="昵称" placeholder="输入昵称" />
			</van-cell-group>
			<van-cell-group inset
				style="width: 312px;height: 48px;opacity: 1;border-radius: 10px;margin-left: 17px;margin-top: 8px;">
				<van-field style="background: rgba(32, 32, 32, 0.05);height: 50;font-size: 15px;align-items: center;" v-model="mobile" label="手机号" placeholder="输入手机号" />
			</van-cell-group>
			<van-cell-group inset
				style="width: 312px;height: 48px;opacity: 1;border-radius: 10px;margin-left: 17px;margin-top: 8px;">
				<van-field style="background: rgba(32, 32, 32, 0.05);height: 50px;font-size: 15px;align-items: center;" v-model="password" type="password" label="密码" placeholder="不得低于6位" />
			</van-cell-group>

			<div style="font-size: 16px;font-weight: 400;margin-left: 29px;margin-top: 36px;">
				选择身份
			</div>

			<div style="display: flex;margin: 0 24px;justify-content: space-between;margin-top: 40px;">
				<div style="text-align: center;" v-for="(item,index) in get_role_list" :key="index" @click="get_role_on(item,index)"> 
					<img style="width: 54px;height: 53px;border-radius: 50%;" :class="{bule:get_role_sun==index}" :src="item.avatar" alt="">
					<div style="font-size: 13px;font-weight: 500;color: rgba(32, 32, 32, 0.6);text-align: center;">
						{{item.name}}
					</div>
				</div> 
				<!-- <div style="text-align: center;">
					<img style="width: 0.54rem;height: 0.53rem;" src="../img/5.png" alt="">
					<div style="font-size: 0.13rem;font-weight: 500;color: rgba(32, 32, 32, 0.6);text-align: center;">快递员
					</div>
				</div>
				<div style="text-align: center;">
					<img style="width: 0.54rem;height: 0.53rem;" src="../img/6.png" alt="">
					<div style="font-size: 0.13rem;font-weight: 500;color: rgba(32, 32, 32, 0.6);text-align: center;">学生
					</div>
				</div>
				<div style="text-align: center;">
					<img style="width: 0.54rem;height: 0.53rem;" src="../img/7.png" alt="">
					<div style="font-size: 0.13rem;font-weight: 500;color: rgba(32, 32, 32, 0.6);text-align: center;">其他
					</div>
				</div> -->


			</div>



		</div>


		<van-button @click="register" style="width: 312px;height: 48px;border-radius:10px;margin-left: 32px;margin-top: 24px;font-size: 20px;"
			type="primary">
			立即注册
		</van-button>












	</div>
</template>

<style scoped>
.black {
	font-size: 18px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 25px;
	color: rgba(32, 32, 32, 1);
	text-align: left;
}

.bule {
	box-shadow: 0 2px 9px  rgba(50, 100, 237, 0.34);
}

.van-cell-group--inset {
	margin: 0;
}
</style>

<script >
import { get_role,register } from "@/api/api";
import { Notify } from 'vant';
export default {
	name: 'RegIster',
	data() {
		return {
			value: '',
			get_role_list:[],
			get_role_sun:-1,

			nickname:'',//用户昵称
			mobile:'',//手机号
			password:'',//登录密码
			role_type:'',//用户角色选择
			wechat_openid:'',//用户公众号openid，用于通知

			img_url:require('../img/3.png')
			
			
		}

	},
	mounted() {
		this.get_role()
		this.wechat_openid=localStorage.getItem('openid')
		// console.log(localStorage.getItem('openid'));
	},
	methods: {
		get_role() {
			// let aaa = 111
			get_role().then(res => {
				if (res.data.code==200) {
					this.get_role_list=res.data.data
				}
				console.log(res);
				console.log(this.get_role_list);
			});

		},
		get_role_on(item,index){
			this.get_role_sun=index
			console.log(item);
			this.img_url=item.avatar
			this.role_type=item.value
		},
		register(){
			var data={
				nickname:this.nickname,
				mobile:this.mobile,
				password:this.password,
				role_type:this.role_type,
				wechat_openid:this.wechat_openid,
			}
			if (this.nickname==''||this.mobile==''||this.password==''||this.role_type==''||this.wechat_openid=='') {
				console.log(data);
				return
			}
			register(data.nickname,data.mobile,data.password,data.role_type,data.wechat_openid).then(res => {
				if (res.data.code==200) {
					console.log(res);
					this.$router.push('/home')
				}else{
					Notify({ type: 'primary', message:res.data.msg });
				}
				
			});
		},

	}

}


</script>
