import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/disposition/common.css'
import '@//disposition/rem';
import '@/disposition/request'
import Vant from 'vant/es';
import 'vant/lib/index.css';
import wx from 'weixin-js-sdk'
import 'lib-flexible'
import 'amfe-flexible'




createApp(App).use(store).use(Vant).use(axios).use(router).use(wx).mount('#app');
