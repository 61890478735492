<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);overflow-y: auto;">

        <div style="padding:0 28px 0 28px;background-color: white;">

            <!-- <van-cell-group inset style="display: flex;justify-content: space-between;align-items: center;">
                <van-field v-model="value" right-icon="search" label="搜索" placeholder="请输入用户名" />
                <van-field v-model="value1"   size="100" placeholder="请输入" />
                <van-icon name="search" size="30" style="margin-right: 20px;"/>
            </van-cell-group> -->

            <!-- <van-search v-model="mobile" show-action placeholder="请输入搜索关键词" @search="onSearch">
                <template #action>
                    <div @click="expresser_list">搜索</div>
                </template>
            </van-search> -->

            <div>
                <van-cell :value="date" @click="show = true">
                    {{ date == '' ? '选择日期区间' : date }}
                </van-cell>
                <van-calendar v-model:show="show" type="range" @confirm="onConfirm" :min-date="minDate"
                    :max-date="maxDate" />

            </div>

        </div>


        <div v-for="(item, index) in list" :key="index" @click="item_on(item)"
            style="margin-left: 28px;margin-right: 28px; margin-top: 10px;opacity: 1;border-radius: 15px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);overflow: hidden;">
            <div
                    style="margin: 20px 20px 10px 20px;font-size: 15px;">
                    设备名称：{{ item.device_name }}
                </div>
                <div
                    style="margin: 20px 20px 10px 20px;font-size: 15px;">
                    设备地址：{{ item.device_addr }}
                </div>
            <div
                style="margin: 20px 20px 10px 20px;font-size: 15px;display: flex;justify-content: space-between;align-items: center;">
                <div>
                    取件码：{{ item.pickup_code }}
                </div>
                <div>
                    手机号：{{ item.mobile==''?'--':item.mobile }}
                </div>
            </div>
            <div
                style="margin: 20px 20px 10px 20px;font-size: 15px;display: flex;justify-content: space-between;align-items: center;">
                <div>
                    时间：{{ item.create_at }}
                </div>
            </div>



        </div>



    </div>
</template>

<style scoped>
.black {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(32, 32, 32, 1);
    text-align: left;
}

.bule {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(50, 100, 237, 1);
    text-align: left;
    vertical-align: top;
}

.van-cell-group--inset {
    margin: 0;
}

.van-cell__value {
    width: 70%;
}
</style>

<script >
import { wechat_device_list, get_wechat_code, express_store_record } from "@/api/api";
export default {
    name: 'StorageRecording',
    data() {
        return {

            list: [],
            date: "",
            show: false,
            minDate: new Date(), //日期可选最小值
            maxDate: new Date(), //日期可选最大值
            start_time: '',//开始时间
            end_time: '',//结束时间
            mobile: '',//根据手机号查询指定快递员业绩
            user_id: '122648',//当前登录用户id
            device_id: '203616390-0',//设备id

        }

    },
    created() {
        let nowDat = new Date();
        let dateY = nowDat.getFullYear();
        let dateM = nowDat.getMonth();
        let dateD = nowDat.getDate();
        // 设置日期可选最小值minDate、最大值maxDate
        this.minDate = new Date(dateY - 1, dateM, dateD);
        //日历可选范围为一年，dateY + 1
        this.maxDate = new Date(dateY + 1, dateM, dateD);
    },
    mounted() {
        // this.getOpenId()
        // this.express_store_record()

        var azz = localStorage.getItem('device_id');
        azz = azz.split('=')[1]
        this.device_id = azz
        console.log(this.device_id);

        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        this.user_id = use



    },
    methods: {

        // 10-公众号操作-设备列表
        wechat_device_list() {
            wechat_device_list().then(res => {
                if (res.data.code == 200) {
                    this.list = res.data.data
                }
                console.log(res);
            })
        },

        // item_on(item) {
        //     console.log(11111);
        //     console.log(item.device_id);
        //     // this.$router.push({path: '/lattice?device_id='+item.device_id});
        //     this.$router.push({ path: '/lattice', query: { device_id: item.device_id } })
        //     // this.$router.push('/purse')
        //     console.log(222222);

        // },


        // 11-快递员业绩列表
        express_store_record() {


            express_store_record(this.start_time, this.end_time, this.user_id).then(res => {
                if (res.data.code == 200) {
                    this.list = res.data.data
                }

                console.log(res);
            })
        },

        onConfirm(e) {
            console.log(e);
            var add = this.getSimpleDate(e[0])
            var arr = this.getSimpleDate(e[1])
            this.date = add + '至' + arr
            this.start_time = add
            this.end_time = arr

            console.log(this.data);
            this.show = false
            this.express_store_record()
        },


        // 转换日期
        getSimpleDate(date) {
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            // var h = date.getHours();
            // var minute = date.getMinutes();
            // minute = minute < 10 ? ('0' + minute) : minute;
            // var s = date.getSeconds();
            // s = s < 10 ? '0' + s : s;
            return y + '/' + m + '/' + d;
        },




        getUrlParam(name) {
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
            let url = window.location.href.split('#')[0]
            let search = url.split('?')[1]
            if (search) {
                var r = search.substr(0).match(reg)
                if (r !== null)
                    return unescape(r[2])
                return null
            } else
                return null
        },

        getOpenId() {
            const code = this.getUrlParam('code')  // 截取路径中的code,
            if (code == null || code === '' || code === false) {
                const local = 'http://shuangxiangguih5.selfbao.com/'
                let appid = 'wxc21c717d8211eced'
                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect';


            } else {
                get_wechat_code(code).then(res => {
                    if (res.data.code == 200) {
                        // var openid = JSON.parse(res.data.data.openid)
                        localStorage.setItem('openid', res.data.data.openid)
                        // alert(res.data.data.openid)
                    }


                })
            }

        },






        // purse() {
        //     this.$router.push('/purse')
        // },
        // orders() {
        //     this.$router.push('/orders')
        // },
    }

}


</script>

