<template>
    <div
        style="height:100vh;overflow-y: scroll;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);">

        <div
            style="z-index: 9999; position: fixed; padding: 20px 15px 17px 15;display: flex;justify-content: space-between;align-items: center;width: 375px;height: 90px;opacity: 1;background: rgba(255, 255, 255, 1);box-shadow: 0px 1px 2px  rgba(0, 0, 0, 0.05);">
            <div :class="{ bule: list_index == index, black: list_index != index }" v-for="(item, index) in list"
                :key="index" @click="list_index_on(index)">
                {{ item }}
            </div>
        </div>

        <div style="padding-top: 95px;overflow: hidden;">
            <!-- 取物品 -->
            <div v-for="(item, index) in data_list" :key="index"
                style="overflow: hidden;margin: 5px 15px;width: 346px;opacity: 1;border-radius: 10px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 12px  rgba(0, 0, 0, 0.09);">
                <div v-if="item.order_type == 2">
                    <div style="margin: 11px 8px;display: flex;justify-content: space-between;align-items: center;">
                        <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);">
                            {{ item.device_name }}
                        </div>
                        <div v-if="item.order_type == 2 && item.open_pick_status == 0"
                            style="font-size: 16px;font-weight: 400;color: rgba(50, 100, 237, 1);">
                            待取件
                        </div>
                        <div v-if="item.order_type == 2 && item.open_pick_status == 2"
                            style="font-size: 16px;font-weight: 400;color: rgba(32, 32, 32, 0.4);">
                            已取件
                        </div>

                    </div>
                    <div
                        style="display: flex;align-items: center;margin-top: 17px;margin-left: 8px;margin-right: 8px;">
                        <img style="width: 10px;height: 13px;" src="../img/14.png" alt="">
                        <div style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 12px;">
                            {{ item.device_name }}
                        </div>
                    </div>

                    <div
                        style="display: flex;align-items: center;margin-top: 17px;justify-content: space-between;margin-left: 8px;margin-right: 8px;">
                        <div style="display: flex;align-items: center;">
                            <img style="width:10px;height: 13px;" src="../img/15.png" alt="">
                            <div
                                style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 12px;">
                                储柜号码
                            </div>
                            <div
                                style="font-size: 14px;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 10px;">
                                {{ item.row_col }}
                            </div>

                        </div>



                    </div>

                    <div v-if="item.need_pay_pick == 1"
                        style="display: flex;align-items: center;margin-top: 17px;margin-left: 8px;margin-right: 8px;">
                        <img style="width: 10px;height: 13px;" src="../img/16.png" alt="">
                        <div style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 12px;">
                            需支付
                        </div>
                        <div style="font-size: 14px;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 10px;">
                            {{ item.pay_amount_pick }}元
                        </div>
                    </div>

                    <div
                        style="width: 330px;height: 0;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 15px 8px 6px 8px;">
                    </div>

                    <div style="display: flex;justify-content:space-between;align-items: center;margin: 0 8px;">
                        <div style="font-size: 10px;font-weight: 400;color: rgba(32, 32, 32, 0.5);">
                            订单时间：{{ item.create_at }}
                        </div>
                        <van-button type="primary" v-if="item.order_type == 2 && item.open_pick_status == 0&&item.need_pay_pick == 1"
                            @click="pay_pick_on(item.order_sn)"
                            style="width: 92px;height: 31px;opacity: 1;border-radius: 10px;">
                            取物品
                        </van-button>
                        <van-button type="primary"
                            v-if="item.order_type == 2 && item.open_pick_status == 0 && item.need_pay_pick == 2"
                            @click="get_box_info_on(item)"
                            style="width: 92px;height: 31px;opacity: 1;border-radius: 10px;">
                            取物品
                        </van-button>
                        <van-button type="default" v-if="item.order_type == 2 && item.open_pick_status == 2"
                            style="width: 92px;height: 31px;opacity: 1;border-radius: 10px;background: rgba(32, 32, 32, 0.3);color: rgba(255, 255, 255, 1);">
                            订单详情
                        </van-button>



                    </div>
                </div>

                <div v-else>
                    <div style="margin: 11px 8px;display: flex;justify-content: space-between;align-items: center;">
                        <div style="font-size: 14px;font-weight: 400;color: rgba(32, 32, 32, 1);">
                            {{ item.device_name }}
                        </div>
                        <div style="font-size: 16px;font-weight: 400;color: rgba(50, 100, 237, 1);">
                            已存件
                        </div>

                    </div>

                    <div style="margin: 17px 26px;display: flex;align-items: center;justify-content: center;">
                        <!-- <div
                            style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-right: 0.07rem;">
                            189****0097
                        </div> -->
                        <!-- <div style="width: 0.48rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(32, 32, 32, 0.1);">
                        </div> -->

                        <img style="width: 33px;height: 33px;margin: 0 7px;" src="../img/39.png" alt="">

                        <!-- <div style="width: 0.48rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(32, 32, 32, 0.1);">
                        </div> -->

                        <!-- <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.07rem;">
                            189****0097
                        </div> -->

                    </div>


                    <div
                        style="display: flex;align-items: center;margin-top: 17px;justify-content: space-between;margin-left: 8px;margin-right: 8px;">
                        <div style="display: flex;align-items: center;">
                            <img style="width: 10px;height: 13px;" src="../img/15.png" alt="">
                            <div
                                style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 12px;">
                                储柜号码
                            </div>
                            <div
                                style="font-size: 14px;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 10px;">
                                {{ item.row_col }}
                            </div>

                        </div>

                    </div>

                    <div
                        style="display: flex;align-items: center;margin-top: 17px;justify-content: space-between;margin-left: 8px;margin-right: 8px;">
                        <div style="display: flex;align-items: center;">
                            <img style="width: 10px;height: 13px;" src="../img/15.png" alt="">
                            <div
                                style="font-size: 12px;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 12px;">
                                取件号码
                            </div>
                            <div
                                style="font-size: 14px;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 10px;">
                                {{ item.pickup_code }}
                            </div>

                        </div>

                    </div>

                    <div
                        style="width: 330px;height: 0;opacity: 1;border: 1px solid rgba(32, 32, 32, 0.1);margin: 15px 8px 6px 8px;">
                    </div>

                    <div style="display: flex;justify-content:space-between;align-items: center;margin: 0 8px;">
                        <div style="font-size: 10px;font-weight: 400;color: rgba(32, 32, 32, 0.5);">
                            订单时间：{{ item.create_at }}
                        </div>
                        <van-button type="default" @click="notify_by_order_on(item)"
                            style="width: 92px;height: 31px;opacity: 1;border-radius: 10px;background: rgba(50, 100, 237, 1);color: rgba(255, 255, 255, 1);">
                            提醒对方
                        </van-button>

                    </div>
                </div>

            </div>

            <!-- 去支付 -->
            <!-- <div
                style="overflow: hidden;margin: 0.05rem 0.15rem;width: 3.46rem;height: 1.94rem;opacity: 1;border-radius: 0.1rem;background: rgba(255, 255, 255, 1);box-shadow: 0rem 0.03rem 0.12rem  rgba(0, 0, 0, 0.09);">

                <div style="margin: 0.11rem 0.08rem;display: flex;justify-content: space-between;align-items: center;">
                    <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                        苏州大学附属中学(苏大附中)6号柜机
                    </div>
                    <div style="font-size: 0.16rem;font-weight: 400;color: rgba(32, 32, 32, 0.4);">
                        已取件
                    </div>

                </div>
                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <img style="width: 0.1rem;height: 0.13rem;" src="../img/14.png" alt="">
                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                        江苏省苏州市苏州工业园区东振路29号
                    </div>
                </div>

                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;justify-content: space-between;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <div style="display: flex;align-items: center;">
                        <img style="width: 0.1rem;height: 0.13rem;" src="../img/15.png" alt="">
                        <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                            储柜号码
                        </div>
                        <div style="font-size: 0.14rem;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 0.1rem;">
                            066820
                        </div>

                    </div>



                </div>

                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <img style="width: 0.1rem;height: 0.13rem;" src="../img/16.png" alt="">
                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                        未支付
                    </div>
                    <div style="font-size: 0.14rem;font-weight: 500;color: rgba(236, 102, 75, 1);margin-left: 0.1rem;">
                        1.5元
                    </div>
                </div>

                <div
                    style="width: 3.3rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(32, 32, 32, 0.1);margin: 0.15rem 0.08rem 0.06rem 0.08rem;">
                </div>

                <div style="display: flex;justify-content:space-between;align-items: center;margin: 0 0.08rem;">
                    <div style="font-size: 0.1rem;font-weight: 400;color: rgba(32, 32, 32, 0.5);">
                        订单时间：2024-8-11 12:25:35
                    </div>
                    <van-button type="warning"
                        style="width: 0.92rem;height: 0.31rem;opacity: 1;border-radius: 0.1rem;">去支付</van-button>

                </div>


            </div> -->

            <!-- 订单详情 -->
            <!-- <div @click="order_details"
                style="overflow: hidden;margin: 0.05rem 0.15rem;width: 3.46rem;height: 1.94rem;opacity: 1;border-radius: 0.1rem;background: rgba(255, 255, 255, 1);box-shadow: 0rem 0.03rem 0.12rem  rgba(0, 0, 0, 0.09);">

                <div style="margin: 0.11rem 0.08rem;display: flex;justify-content: space-between;align-items: center;">
                    <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                        苏州大学附属中学(苏大附中)6号柜机
                    </div>
                    <div style="font-size: 0.16rem;font-weight: 400;color: rgba(32, 32, 32, 0.4);">
                        已取件
                    </div>

                </div>
                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <img style="width: 0.1rem;height: 0.13rem;" src="../img/14.png" alt="">
                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                        江苏省苏州市苏州工业园区东振路29号
                    </div>
                </div>

                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;justify-content: space-between;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <div style="display: flex;align-items: center;">
                        <img style="width: 0.1rem;height: 0.13rem;" src="../img/15.png" alt="">
                        <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                            储柜号码
                        </div>
                        <div style="font-size: 0.14rem;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 0.1rem;">
                            066820
                        </div>

                    </div>



                </div>

                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <img style="width: 0.1rem;height: 0.13rem;" src="../img/16.png" alt="">
                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                        已支付
                    </div>
                    <div style="font-size: 0.14rem;font-weight: 500;color: rgba(236, 102, 75, 1);margin-left: 0.1rem;">
                        1.5元
                    </div>
                </div>

                <div
                    style="width: 3.3rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(32, 32, 32, 0.1);margin: 0.15rem 0.08rem 0.06rem 0.08rem;">
                </div>

                <div style="display: flex;justify-content:space-between;align-items: center;margin: 0 0.08rem;">
                    <div style="font-size: 0.1rem;font-weight: 400;color: rgba(32, 32, 32, 0.5);">
                        订单时间：2024-8-11 12:25:35
                    </div>
                    <van-button type="default"
                        style="width: 0.92rem;height: 0.31rem;opacity: 1;border-radius: 0.1rem;background: rgba(32, 32, 32, 0.3);color: rgba(255, 255, 255, 1);">订单详情</van-button>

                </div>


            </div> -->

            <!-- 已存件 -->
            <!-- <div
                style="overflow: hidden;margin: 0.05rem 0.15rem;width: 3.46rem;height: 1.94rem;opacity: 1;border-radius: 0.1rem;background: rgba(255, 255, 255, 1);box-shadow: 0rem 0.03rem 0.12rem  rgba(0, 0, 0, 0.09);">

                <div style="margin: 0.11rem 0.08rem;display: flex;justify-content: space-between;align-items: center;">
                    <div style="font-size: 0.14rem;font-weight: 400;color: rgba(32, 32, 32, 1);">
                        苏州大学附属中学(苏大附中)6号柜机
                    </div>
                    <div style="font-size: 0.16rem;font-weight: 400;color: rgba(50, 100, 237, 1);">
                        已存件
                    </div>

                </div>

                <div style="margin: 0.17rem 0.26rem;display: flex;align-items: center;">
                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-right: 0.07rem;">
                        189****0097
                    </div>
                    <div style="width: 0.48rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(32, 32, 32, 0.1);"></div>

                    <img style="width: 0.33rem;height: 0.33rem;margin: 0 0.07rem;" src="../img/39.png" alt="">

                    <div style="width: 0.48rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(32, 32, 32, 0.1);"></div>

                    <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.07rem;">
                        189****0097
                    </div>

                </div>


                <div
                    style="display: flex;align-items: center;margin-top: 0.17rem;justify-content: space-between;margin-left: 0.08rem;margin-right: 0.08rem;">
                    <div style="display: flex;align-items: center;">
                        <img style="width: 0.1rem;height: 0.13rem;" src="../img/15.png" alt="">
                        <div style="font-size: 0.12rem;font-weight: 400;color: rgba(32, 32, 32, 0.6);margin-left: 0.12rem;">
                            储柜号码
                        </div>
                        <div style="font-size: 0.14rem;font-weight: 500;color: rgba(50, 100, 237, 1);margin-left: 0.1rem;">
                            066820
                        </div>

                    </div>

                </div>


                <div
                    style="width: 3.3rem;height: 0rem;opacity: 1;border: 0.01rem solid rgba(32, 32, 32, 0.1);margin: 0.15rem 0.08rem 0.06rem 0.08rem;">
                </div>

                <div style="display: flex;justify-content:space-between;align-items: center;margin: 0 0.08rem;">
                    <div style="font-size: 0.1rem;font-weight: 400;color: rgba(32, 32, 32, 0.5);">
                        订单时间：2024-8-11 12:25:35
                    </div>
                    <van-button type="default" @click="Certificates"
                        style="width: 0.92rem;height: 0.31rem;opacity: 1;border-radius: 0.1rem;background: rgba(50, 100, 237, 1);color: rgba(255, 255, 255, 1);">
                        提醒对方
                    </van-button>

                </div>


            </div> -->
        </div>










    </div>
</template>

<style scoped>
.black {
    width: 78px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(32, 32, 32, 0.6);
    line-height: 40px;
    text-align: center;
}

.bule {
    width: 78px;
    height: 40px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(50, 100, 237, 1);
    box-shadow: 0 2px 9px rgba(50, 100, 237, 0.34);
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

.van-cell-group--inset {
    margin: 0;
}
</style>


<script >
import { all_order_list, notify_by_order, pay_pick, get_box_info } from "@/api/api";
import { Dialog } from 'vant';
export default {
    name: 'Orders',
    data() {
        return {
            value: '',
            list: ['全部', '待取件', '已存件', '已取件'],
            list_index: 0,

            device_id: "",
            user_id: '',
            page: 1,
            limit: '10000',
            type: '10',
            data_list: [],
            openid: '',
            pay_recharge_obj: {},

            loading: false,
            finished: false,


        }

    },
    mounted() {
        var azz = localStorage.getItem('device_id');
        azz = azz.split('=')[1]
        this.device_id = azz
        console.log(this.device_id);

        var use = localStorage.getItem('user_id');
        // use = JSON.parse(use)
        this.user_id = use

        this.openid = localStorage.getItem('openid')

        this.all_order_list()
    },
    methods: {

        // 04订单-全部
        all_order_list() {

            var data = {
                device_id:this.device_id,
                user_id: this.user_id,
                page: this.page,
                limit: this.limit,
                type: this.type
            }

            all_order_list(data).then(res => {
                if (res.data.code == 200) {
                    if (this.page>1) {
                        this.data_list.push(res.data.data.list)
                    }else{
                        this.data_list = res.data.data.list
                    }
                    

                    this.loading = false

                    if (res.data.data.list.length < 10) {
                        this.finished = true
                    } else {
                        this.finished = false
                    }
                }
                console.log(res);
            })
        },

        // 点击变色
        list_index_on(index) {
            this.list_index = index
            this.type = (index + 1) * 10
            console.log(this.type);
            this.page=1
            this.data_list=[]
            this.all_order_list();
        },

        // 提醒取件
        notify_by_order_on(item) {
            console.log(item);
            notify_by_order(this.device_id, item.order_id).then(res => {
                console.log(res);
                if (res.data.code == 200) {
                    Dialog.alert({
                        message: '提醒对方成功',
                    }).then(() => {
                        // on close
                    });
                }

            })
            // this.$router.push('/certificates')
        },

        // 03-支付-取件超时支付
        pay_pick_on(order_sn) {
            var data = {
                device_id: this.device_id,
                order_sn: order_sn,
                openid: this.openid
            }
            pay_pick(data).then(res => {
                if (res.data.code == 200) {
                    console.log(res);
                    this.pay_recharge_obj = res.data.data
                    this.callpay()
                }
                console.log(res);
            })
        },

        jsApiCall() {
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', this.pay_recharge_obj,
                (res) => {

                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        console.log(res.err_msg);
                        // this.$router.push('/recharge')
                    } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                        alert("用户取消支付!");
                    } else {
                        // alert(res.err_msg)
                        alert("支付失败!");
                    }
                }
            );
        },

        callpay() {
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', this.jsApiCall(), false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', this.jsApiCall());
                    document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall());
                }
            } else {
                this.jsApiCall();
            }
        },


        // 不需要支付就取件
        get_box_info_on(item) {
            get_box_info(item.order_id).then(res => {
                if (res.data.code == 200) {
                    Dialog.alert({
                        message: '开箱成功：' + item.row_col,
                    }).then(() => {
                        // on close
                        this.all_order_list()
                    });
                }
                console.log(res);
            })
        },

        // 触底加载
        // account_record_list_load() {
        //     this.loading = true;
        //     this.page = this.page + 1
        //     this.all_order_list()

        // },



        order_details() {
            this.$router.push('/order_details')
        },
    }

}


</script>

