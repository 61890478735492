<template>
    <div
        style="height:100vh;overflow: hidden;background:linear-gradient(180deg,rgba(227,235,255,1)0%,rgba(255,255,255,1)100%);overflow-y: auto;">

        <div v-for="(item,index) in list" :key="index" @click="item_on(item)"
            style="margin-left: 28px;margin-right: 28px; margin-top: 10px;opacity: 1;border-radius: 15px;background: rgba(255, 255, 255, 1);box-shadow: 0 3px 5px  rgba(0, 0, 0, 0.09);overflow: hidden;">
            <div style="margin: 20px 0 10px 20px;font-size: 15px;">
                设备名称：{{item.device_name}}
            </div>    
            <div style="margin: 20px 0 10px 20px;font-size: 15px;">
                设备地址：{{item.device_addr}}
            </div>      



        </div>



    </div>
</template>

<style scoped>
.black {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(32, 32, 32, 1);
    text-align: left;
}

.bule {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    color: rgba(50, 100, 237, 1);
    text-align: left;
    vertical-align: top;
}

.van-cell-group--inset {
    margin: 0;
}
</style>

<script >
import { wechat_device_list,get_wechat_code } from "@/api/api";
export default {
    name: 'Equipment',
    data() {
        return {

            list:[],
        }

    },
    mounted() {
        // this.getOpenId()
        this.wechat_device_list()


    },
    methods: {

        // 10-公众号操作-设备列表
        wechat_device_list(){
            wechat_device_list().then(res=>{
                if (res.data.code==200) {
                    this.list=res.data.data
                }
                console.log(res);
            })
        },
      
        item_on(item){
            console.log(11111);
            console.log(item.device_id);
            // this.$router.push({path: '/lattice?device_id='+item.device_id});
            this.$router.push({path: '/lattice',query:{ device_id:item.device_id}})
            // this.$router.push('/purse')
            console.log(222222);

        },



        getUrlParam(name) {
			var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
			let url = window.location.href.split('#')[0]
			let search = url.split('?')[1]
			if (search) {
				var r = search.substr(0).match(reg)
				if (r !== null)
					return unescape(r[2])
				return null
			} else
				return null
		},

		getOpenId() {
			const code = this.getUrlParam('code')  // 截取路径中的code,
			if (code == null || code === '' || code === false) {
				const local = 'http://shuangxiangguih5.selfbao.com/'
				let appid = 'wxc21c717d8211eced'
				window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect';


			} else {
				get_wechat_code(code).then(res => {
					if (res.data.code == 200) {
						// var openid = JSON.parse(res.data.data.openid)
						localStorage.setItem('openid', res.data.data.openid)
						// alert(res.data.data.openid)
					}


				})
			}

		},




        purse() {
            this.$router.push('/purse')
        },
        orders() {
            this.$router.push('/orders')
        },
    }

}


</script>

